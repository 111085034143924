import type { UseFormSetError } from 'react-hook-form';
import type { ApolloError } from '@apollo/client';
import toast from '@/utils/toast';
import { selectValidationErrors } from './errors';
import { type Field } from '@/models';

export const errorMessages = {
  required: 'Поле обязательно для заполнения',
  invalidPhone: 'Некорректный номер',
  invalidEmail: 'Некорректный email',
};

export const regexp = {
  email: /(.+)@(.+){2,}\.(.+){2,}/,
};

export const infoMessages = {
  writeOnly: 'Заполняется только для изменения',
};

/**
 * @param setError a method from react-hook-form
 * @param model In case we need to hadle validation differently --
 * e.g. some fields returned by the server are not in the form, and we
 * want to show notifications for these fields.
 * To determine what fileds are present in a form, in some cases we can
 * use 'data', or _.omit(data, ['field1', 'field2']),
 * or just pass an object with known fields as its keys
 * @returns validator function
 */
export const getFormValidator = (setError: UseFormSetError<any>, model?: unknown) => {
  return (error: ApolloError) => {
    if (error.networkError) {
      toast.error(error.networkError.message);
      return;
    }
    const errors = selectValidationErrors(error.graphQLErrors);
    // highlight form errors
    errors.forEach(({ name, data: errorData }) => {
      if (!model || Object.prototype.hasOwnProperty.call(model, name)) {
        setError(name, errorData);
      }
    });
    if (!model) {
      return;
    }
    // show notifications for the fields not shown in a form
    errors.forEach((args) => {
      const { data: errorData, name } = args;
      if (model && !Object.prototype.hasOwnProperty.call(model, name)) {
        if (errorData.message) {
          toast.error(errorData.message, {
            toastId: errorData.message,
          });
        }
      }
    });
  };
};

/**
 * If a validation error occurs, the server represents them like that
 * fields.0.value: ["error message"]
 * which is fine, but there's a problem when we don't send all fields from a form.
 * (And there are cases like that -- when there are non-nullable fields that are write-only, so
 * we don't send them when they weren't filled by the user)
 * In this case, the index is relative to the field list we've sent, not to the field list in
 * the form.
 *
 * Otherwise, works just like getFormValidator
 */
export const getFormValidatorSparse = (setError: UseFormSetError<any>, data: any, initialData: Field[]) => {
  return (error: ApolloError) => {
    if (error.networkError) {
      toast.error(error.networkError.message);
      return;
    }
    const errors = selectValidationErrors(error.graphQLErrors);
    const indexAdjustedErrors = errors.map((error) => {
      const name = error.name; // fields.$index.value
      const nameChunks: string[] = name.split('.');
      const index = Number(nameChunks[1]);
      const dataItem = data[index];
      const actualFieldName = dataItem.field;

      const indexInForm = initialData.findIndex(({ name }) => {
        return name === actualFieldName;
      });
      const newName = `${nameChunks[0]}.${indexInForm}.${nameChunks[2]}`;
      return {
        ...error,
        name: newName,
      };
    });
    // highlight form errors
    indexAdjustedErrors.forEach(({ name, data }) => {
      setError(name, data);
    });
  };
};

export const showValidationErrorsNotifications = (error: ApolloError) => {
  const errors = selectValidationErrors(error.graphQLErrors);
  // show notifications for the fields not shown in a form
  errors.forEach(({ data }) => {
    toast.error(data.message, {
      toastId: data.message,
    });
  });
};

// TODO type
export const selectByValue = (options: any[], value: any) => {
  return options.find((item) => item.value === value);
};

export const selectAllByValue = (options: any[], value: any) => {
  return options.filter((item) => value.includes(item.value));
};

export const selectValues = (options: any[]) => {
  return options.map((item) => item.value);
};

export const rulesRequired = { required: errorMessages.required };
