import { useCurrentRole } from '@/hooks/useCurrentRole';
import { useIsAuthorized } from '@/hooks/useIsAuthorized';
import clsx from 'clsx';
import { LandingAsyncSelect } from './LandingAsyncSelect';
import { CABINET_PATH_CHUNK, PROJECT_PATH_CHUNK } from '@/constants/routing';
import { gql } from '@apollo/client';
import { AdminUserTypeEnum, type CabinetProjectForListFieldsFragment } from '@/gql/generated';

const CabinetProjectListDocument = gql`
  query cabinetProjectList(
    $ids: [Int]
    $search: String
    $limit: Int
    $offset: Int
    $orders: [CabinetProjectListOrder]
  ) {
    cabinetProjectList(ids: $ids, search: $search, limit: $limit, offset: $offset, orders: $orders) {
      items {
        id
        actual_requisite {
          name
          id
        }
        email
      }
      count
    }
  }
`;

interface Props {
  className?: string;
}

export const LandingProjectSelect = (props: Props) => {
  const { className } = props;
  const isAuthorized = useIsAuthorized();
  const currentRole = useCurrentRole();
  const isOrganization = currentRole === AdminUserTypeEnum.Client;
  if (!isAuthorized || !isOrganization) {
    return null;
  }
  return (
    <div className={clsx(className)}>
      <LandingAsyncSelect<CabinetProjectForListFieldsFragment>
        query={CabinetProjectListDocument}
        transformItem={(item) => ({ value: item.id, label: item.actual_requisite?.name })}
        onChange={(data) => {
          window.location.replace(`/${CABINET_PATH_CHUNK}/${PROJECT_PATH_CHUNK}/${Number(data)}`);
        }}
        isMulti={false}
      />
    </div>
  );
};
