export const ensureArray = <T>(arg: T[] | T | null): T[] => {
  if (!arg) {
    return [];
  }
  if (Array.isArray(arg)) {
    return arg;
  }
  return [arg];
};

export const ensureSingleValue = <T>(arg: T[] | T | null): T | null => {
  if (!arg) {
    return null;
  }
  if (!Array.isArray(arg)) {
    return arg;
  }
  return arg[0] ?? null;
};
