import { gql, useApolloClient, useQuery } from '@apollo/client';
import { LandingButton } from '../LandingButton';
import styles from './LandingAuthMenu.module.scss';
import { getAuthRedirectLink } from '@/pages/AuthPage/util';
import { deleteToken } from '@/utils/token';
import { getOperationName } from '@apollo/client/utilities';
import { LandingLoginUserIcon } from '../icons/LandingLoginUserIcon';
import { LandingProjectSelect } from '../LandingAsyncSelect/LandingProjectSelect';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';
import clsx from 'clsx';
import { LandingLoginButton } from '../icons/LandingLoginButton';
import { useIsAuthorized } from '@/hooks/useIsAuthorized';
import { LandingCloseIcon } from '../icons/LandingCloseIcon';

const ProfileDocument = gql`
  query profile {
    profile {
      fio
      email
      phone
      is_admin_account
      is_organization_account
    }
  }
`;

interface Props {
  setMenuIsOpen: (value: boolean) => void;
}

export const LandingAuthMenu = ({ setMenuIsOpen }: Props) => {
  const profileResponse = useQuery(ProfileDocument);
  const client = useApolloClient();
  const profile = profileResponse.data?.profile;
  const isAuthorized = useIsAuthorized();

  const onClose = () => {
    setTimeout(() => {
      setMenuIsOpen(false);
    }, 1);
  };

  const logout = () => {
    deleteToken();
    client.clearStore();
    client.cache.reset();
    client.cache.evict({ fieldName: getOperationName(ProfileDocument)! });
    client.cache.gc();
    setMenuIsOpen(false);
  };

  return (
    <>
      <div className={styles.authMenuWrapper}>
        <div className={styles.authMenuTop}>
          <div>
            <div className={styles.authMenuHeader}>
              <LandingLoginUserIcon />
              <span>Ваш акаунт</span>
            </div>
            <div className={styles.authMenuEmail} title={profile?.email}>
              {profile?.email}
            </div>
          </div>

          <div className={styles.menuButton} onClick={onClose}>
            <LandingCloseIcon />
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <LandingProjectSelect className={styles.authMenuSelect} />
        </div>
        <div>
          {profile && (
            <a href={getAuthRedirectLink(profile)}>
              <LandingButton variant="fancy" className={styles.authMenuButton}>
                Цифровые решения
                <LandingChevronRightIcon />
              </LandingButton>
            </a>
          )}
        </div>

        <div
          onClick={() => {
            if (isAuthorized) {
              logout();
            } else {
              setMenuIsOpen(true);
            }
          }}
          className={styles.authLogoutButton}
        >
          <LandingButton variant="secondary" className={clsx(styles.authLogoutButton)}>
            {isAuthorized ? 'ВЫХОД' : 'ВХОД'}
            {isAuthorized ? <span /> : <LandingLoginButton />}
          </LandingButton>
        </div>
      </div>
      <div
        className={styles.authMenuOverlay}
        onClick={() => {
          setMenuIsOpen(false);
        }}
      ></div>
    </>
  );
};
