// import { ModalCheckbox } from '../ModalCheckbox';
import { ModalInput } from '../ModalInput';
import styles from '../LandingModal/LandingModal.module.scss';
import stylesAuth from './LandingModalAuth.module.scss';
import { LandingModal } from '../LandingModal';
import { LandingButton } from '../LandingButton';
import { FormProvider, useForm } from 'react-hook-form';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { setToken } from '@/utils/token';
import toast from '@/utils/toast';
import { errorMessages, getFormValidator } from '@/utils/form';
import { getAuthRedirectLink } from '@/pages/AuthPage/util';
import { AUTH_PATH_CHUNK, RESET_PASSWORD_EMAIL_PATH_CHUNK } from '@/constants/routing';
import clsx from 'clsx';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';

const ProfileDocument = gql`
  query profile {
    profile {
      fio
      email
      phone
      is_admin_account
      is_organization_account
    }
  }
`;

const LoginDocument = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  openBidModal: () => void;
}

export const LandingModalAuth = (props: Props) => {
  const { open, onClose, openBidModal } = props;
  const defaultValues = {
    email: '',
    password: '',
  };
  const methods = useForm({ defaultValues });
  const { handleSubmit, setError, watch, reset } = methods;

  const [requestLogin] = useMutation(LoginDocument);
  const [requestProfile] = useLazyQuery(ProfileDocument, { fetchPolicy: 'network-only' });

  const onSubmit = async (data: any) => {
    await requestLogin({
      variables: {
        ...data,
      },
    })
      .then(async (response) => {
        setToken(response.data?.login.token ?? '');
        const profileResponse = await requestProfile();
        const profile = profileResponse.data?.profile;
        if (!profile) {
          toast.error('Something went wrong', { toastId: 'profile-error' });
          return;
        }
        window.location.replace(getAuthRedirectLink(profile));
      })
      .catch(getFormValidator(setError));
  };

  const email = watch('email');
  const password = watch('password');

  if (!open) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <LandingModal
        open={open}
        onClose={() => {
          reset(defaultValues);
          onClose();
        }}
      >
        <form className={stylesAuth.modalForm} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className={styles.modalHeader}>Вход в личный кабинет</div>
            <div className={clsx(styles.modalText, stylesAuth.modalText)}>
              Введите ваши данные для входа в личный кабинет
            </div>
          </div>
          <div className={styles.modalForm}>
            <ModalInput placeholder="Логин" name="email" rules={{ required: errorMessages.required }} />
            <ModalInput
              placeholder="Пароль"
              name="password"
              type="password"
              rules={{ required: errorMessages.required }}
            />
            <div className={stylesAuth.authModalPasswordLinkWrap}>
              <a
                href={`/${AUTH_PATH_CHUNK}/${RESET_PASSWORD_EMAIL_PATH_CHUNK}`}
                className={stylesAuth.authModalPasswordLink}
              >
                Забыли пароль?
              </a>
            </div>
          </div>
          <div className={stylesAuth.inputWrap}>
            <LandingButton disabled={!email || !password} variant="fancy">
              Вход
              <LandingChevronRightIcon />
            </LandingButton>
            <LandingButton
              variant="secondary"
              onClick={() => {
                openBidModal();
                onClose();
                reset(defaultValues);
              }}
              type="button"
            >
              Запросить доступ
              <LandingChevronRightIcon />
            </LandingButton>
          </div>
        </form>
      </LandingModal>
    </FormProvider>
  );
};
