import { useCallback, useEffect, useState } from 'react';
import { type OperationVariables, useLazyQuery, type QueryResult } from '@apollo/client';
import { DEBOUNCE_TIMEOUT } from '@/constants/events';

import styles from './AsyncSelect.module.scss';
import { getItems, loadAllIds } from '@/utils/apollo';
import { type SelectValueItem } from '../Select/Select';
import { debounce, uniqBy, isEqual, difference } from 'lodash';
import { getFormValidator } from '@/utils/form';
import { type AsyncSelectDefaultValue, type AsyncSelectProps } from './AsyncSelectProps';
import { type MenuPlacement } from 'react-select';
import { type AsyncSelectInnerProps } from './AsyncSelectInnerProps';
import { type EntityWithId } from '@/utils/sortById';
import { type SelectValueWithData, type SelectValueItemWithData, type SelectValueType } from './models';
import { ensureArray } from '@/utils/array';

interface LoadItemsArgs {
  limit: number;
  offset: number;
  search: string;
}

export const DEFAULT_PAGE_SIZE = 10;

const getListDefault = (response: QueryResult) => {
  return getItems(response?.data);
};

const compareIds = (a: number, b: number) => a - b;

const isSameValue = (a: AsyncSelectDefaultValue, b: AsyncSelectDefaultValue) => {
  if (!Array.isArray(a)) {
    a = [a];
  }
  if (!Array.isArray(b)) {
    b = [b];
  }
  a = [...a].sort(compareIds);
  b = [...b].sort(compareIds);
  return isEqual(a, b);
};

export const useAsyncSelect = <T extends EntityWithId>(props: AsyncSelectProps<T>): AsyncSelectInnerProps<T> => {
  const {
    query,
    transformItem: transformItemProp,
    pageSize = DEFAULT_PAGE_SIZE,
    onChange,
    onInputValueChange,
    defaultInputValue = '',
    defaultValue = [],
    isMulti,
    isClearable = true,
    getList = getListDefault,
    closeMenuOnSelect = false,
    queryVars = {},
    queryVarsInitial,
    maxValues = Infinity,
    isInvalid,
    classNames = {},
    ariaLabel = 'react-select',
    clearValueOnClose = true,
    components = {},
    preloadItems = false,
    controlShouldRenderValue = true,
    hideSelectedOptions = true,
    ...rest
  } = props;

  const transformItem = (data: T): SelectValueItemWithData<T> => {
    return {
      ...transformItemProp(data),
      originalData: data,
    };
  };

  const [offset, setOffset] = useState(0);
  const [inputValue, setInputValue] = useState(defaultInputValue);
  useEffect(() => {
    setInputValue(defaultInputValue);
  }, [defaultInputValue]);

  const [currentItems, setCurrentItems] = useState<T[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [value, setValue] = useState<Array<SelectValueItemWithData<T>>>([]);
  const [prevDefaultValue, setPrevDefaultValue] = useState<AsyncSelectDefaultValue>([]);
  const [isLoading, setIsLoading] = useState(false);

  const resetPagination = () => {
    setOffset(0);
    setHasMore(true);
  };

  const [loadItemsQuery] = useLazyQuery(query, { fetchPolicy: 'cache-and-network' });
  const selectedItemsQuery = useLazyQuery(query, { fetchPolicy: 'network-only' });

  const handleDefaultValueChange = async () => {
    let newValue: Array<SelectValueItemWithData<T>> = [];
    const isEmpty = !defaultValue || (Array.isArray(defaultValue) && !defaultValue.length);

    if (!isEmpty) {
      const idsToLoad = Array.isArray(defaultValue) ? defaultValue : [defaultValue];
      const alreadyLoadedIds = currentItems.map((item) => item.id);
      const alreadyLoadedItems = currentItems.filter((item) => idsToLoad.some((idToLoad) => item.id === idToLoad));
      const notLoadedIds = difference(idsToLoad, alreadyLoadedIds);
      let newItems: T[] = [];
      if (notLoadedIds.length) {
        setIsLoading(true);
        const response = await loadAllIds(selectedItemsQuery, 50, notLoadedIds, queryVarsInitial ?? queryVars);
        setIsLoading(false);
        newItems = getItems(response);
      }
      newValue = [...alreadyLoadedItems, ...newItems].map(transformItem);
    }
    setValue(newValue);
  };

  if (!isSameValue(prevDefaultValue, defaultValue)) {
    handleDefaultValueChange();
    setPrevDefaultValue(defaultValue);
  }

  const loadItems = useCallback(
    async (args: LoadItemsArgs) => {
      const variables: OperationVariables = {
        ...queryVars,
        ...args,
      };
      const response: QueryResult<T, OperationVariables> | null = await loadItemsQuery({ variables }).catch((e) => {
        console.log('error', e.message);
        getFormValidator(() => {}, {})(e);
        return null;
      });
      if (!response) {
        return { items: [] };
      }
      return {
        items: getList(response),
      };
    },
    [queryVars],
  );

  const hasNonSelected = (items: T[], value: SelectValueType) => {
    const typedValue = value as SelectValueItem[];
    if (!Array.isArray(value)) {
      return true;
    }
    return items.some((item) => typedValue.every((valueItem) => item.id !== valueItem.value));
  };

  const loadItemsUntilNonSelected = useCallback(
    async (args: LoadItemsArgs) => {
      const items: T[] = [];
      let offset = args.offset;
      let reachedEnd = false;
      setIsLoading(true);
      do {
        const result = await loadItems({ ...args, offset });
        items.push(...result.items);
        offset += args.limit;
        if (result.items.length < pageSize) {
          reachedEnd = true;
        }
      } while (!hasNonSelected(items, value) && !reachedEnd);
      setIsLoading(false);
      setHasMore(!reachedEnd);
      return {
        items,
      };
    },
    [queryVars],
  );

  const goToNextPageIfAllItemsSelected = (selectedItems: SelectValueItem[], allItems: T[]) => {
    if (!selectedItems.length || !allItems.length) {
      return;
    }
    const notSelectedCurrentItems = allItems.filter(
      (item) => !selectedItems.find((selectedItem) => selectedItem.value === item.id),
    );
    if (!notSelectedCurrentItems.length) {
      loadNextPage();
    }
  };

  const handleChange = (data: SelectValueWithData<T>) => {
    console.log('handleChange', data);
    const dataArray = ensureArray(data);
    setValue(dataArray);
    if (Array.isArray(data)) {
      goToNextPageIfAllItemsSelected(data, currentItems);
      onChange?.(
        // TODO type
        data.map((item) => item.value) as any,
        data as any,
      );
    } else {
      // TODO type
      onChange?.(data ? data.value : null, (data ?? null) as any);
    }
  };

  const loadOnSearch = useCallback(
    debounce(async (search: string) => {
      const data = await loadItemsUntilNonSelected({
        offset,
        limit: pageSize,
        search,
      });
      setCurrentItems(data.items);
    }, DEBOUNCE_TIMEOUT),
    [offset, pageSize],
  );

  const handleInputChange = useCallback((inputValue: string, meta: any) => {
    if (meta.action === 'menu-close') {
      resetPagination();
      if (clearValueOnClose) {
        setInputValue('');
        onInputValueChange?.('');
      }
      return;
    }
    if (meta.action !== 'input-change') {
      return;
    }
    setInputValue(inputValue);
    onInputValueChange?.(inputValue);
    setOffset(0);
    resetPagination();
    loadOnSearch(inputValue);
  }, []);

  const addToCurrentItems = (newItems: T[]) => {
    setCurrentItems((prev) => uniqBy([...prev, ...newItems], 'id'));
  };

  const loadNextPage = async () => {
    if (!hasMore || isLoading) {
      return;
    }
    const newOffset = offset + pageSize;
    setOffset(newOffset);
    const response = await loadItemsUntilNonSelected({
      offset: newOffset,
      limit: pageSize,
      search: inputValue,
    });
    addToCurrentItems(response.items);
  };

  const onMenuScrollToBottom = () => {
    loadNextPage();
  };

  const onMenuOpen = async () => {
    setCurrentItems([]);
    const response = await loadItemsUntilNonSelected({
      offset: 0,
      limit: pageSize,
      search: inputValue,
    });
    setCurrentItems(response.items);
  };

  useEffect(() => {
    if (preloadItems) {
      onMenuOpen();
    }
  }, []);

  return {
    query,
    transformItem,
    value,
    inputValue,
    onChange: handleChange,
    onInputChange: handleInputChange,
    isMulti,
    isClearable,
    closeMenuOnSelect,
    options: currentItems.map(transformItem),
    onMenuScrollToBottom,
    classNames: {
      ...classNames,
      control: (data: any) => `${isInvalid ? styles.asyncSelect_invalid : ''} ${classNames.control?.(data) ?? ''}`,
    },
    components: {
      IndicatorSeparator: () => null,
      LoadingIndicator: () => null,
      NoOptionsMessage: () => <div className="text-center py-2">Нет данных</div>,
      ...components,
    },
    isOptionDisabled: (option: any, selectValue: any) => selectValue.length >= maxValues,
    onMenuOpen,
    menuPlacement: 'auto' as MenuPlacement,
    filterOption: (options: any) => options,
    menuShouldScrollIntoView: false,
    'aria-label': ariaLabel,
    isLoading,
    controlShouldRenderValue,
    hideSelectedOptions,
    ...rest,
  };
};
