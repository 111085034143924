import { ModalCheckboxConnected } from '../ModalCheckbox';
import { ModalInput } from '../ModalInput';
import styles from '../LandingModal/LandingModal.module.scss';
import { LandingModal } from '../LandingModal';
import { LandingButton } from '../LandingButton';
import { FormProvider, useForm } from 'react-hook-form';
import { LandingDadataSelect } from '../LandingDadataSelect';
import { errorMessages, getFormValidator } from '@/utils/form';
import { CABINET_PATH_CHUNK, PROJECT_PATH_CHUNK } from '@/constants/routing';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { setToken } from '@/utils/token';
import { LandingChevronRightIcon } from '../icons/LandingChevronRightIcon';
import { yandexMetrika } from '@/utils/metrika';

interface LandingOrganization {
  kpp: string;
  ogrn: string;
  name: string;
  inn: string;
}

const CreateDemoBidDocument = gql`
  mutation createDemoBid(
    $fio: String!
    $phone: String!
    $email: String!
    $organization_name: String!
    $organization_kpp: String
    $organization_inn: String!
    $organization_ogrn: String
  ) {
    createDemoBid(
      fio: $fio
      phone: $phone
      email: $email
      organization_name: $organization_name
      organization_kpp: $organization_kpp
      organization_inn: $organization_inn
      organization_ogrn: $organization_ogrn
    )
  }
`;

const LoginDemoDocument = gql`
  mutation loginDemo {
    loginDemo {
      token
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

export const LandingModalBid = (props: Props) => {
  const [createDemoBid] = useMutation(CreateDemoBidDocument);
  const [selectedOrganization, setSelectedOrganization] = useState<LandingOrganization | null>(null);
  const [runDemoLogin] = useMutation(LoginDemoDocument);
  const { open, onClose } = props;
  const methods = useForm({
    defaultValues: {
      agree: true,
      inn: '',
      kpp: '',
      ogrn: '',
    },
  });
  const { setValue, watch, handleSubmit, setError } = methods;
  const inn = watch('inn');
  const kpp = watch('kpp');
  const ogrn = watch('ogrn');
  const agree = watch('agree');

  if (!open) {
    return null;
  }

  const onOrganizationSelect = (data: LandingOrganization) => {
    const { inn, ogrn, kpp } = data;
    setValue('inn', `ИНН ${inn}`);
    setValue('kpp', `КПП ${kpp}`);
    setValue('ogrn', `ОГРН ${ogrn}`);
    setSelectedOrganization(data);
  };

  const onSubmit = async (data: any) => {
    const { name, kpp, inn, ogrn } = selectedOrganization ?? {};
    await createDemoBid({
      variables: {
        fio: data.name,
        phone: data.phone,
        email: data.email,
        organization_name: name ?? '',
        organization_kpp: kpp,
        organization_inn: inn ?? '',
        organization_ogrn: ogrn,
      },
    })
      .then(async () => {
        const response = await runDemoLogin();
        const demoToken = response?.data?.loginDemo?.token;
        setToken(demoToken ?? '');
        yandexMetrika(97444920, 'reachGoal', 'lead');
        window.location.replace(`/${CABINET_PATH_CHUNK}/${PROJECT_PATH_CHUNK}/?demo=true`);
      })
      .catch(getFormValidator(setError));
  };

  return (
    <FormProvider {...methods}>
      <LandingModal open={open} onClose={onClose}>
        <div className={styles.modalHeader}>Получите демо-доступ</div>
        <div className={styles.modalText}>Оставьте данные и вам станет доступна версия демонтстрирующая функционал</div>
        <form className={styles.modalForm} onSubmit={handleSubmit(onSubmit)}>
          <ModalInput placeholder="Ваше имя" name="name" rules={{ required: errorMessages.required }} />
          <ModalInput placeholder="Телефон" name="phone" rules={{ required: errorMessages.required }} />
          <ModalInput placeholder="Почта" name="email" rules={{ required: errorMessages.required }} />
          <LandingDadataSelect onSelect={onOrganizationSelect} />
          <div className="d-flex gap-1">
            {!!inn && <ModalInput placeholder="ИНН" name="inn" readOnly={true} />}
            {!!kpp && <ModalInput placeholder="КПП" name="kpp" readOnly={true} />}
          </div>
          {!!ogrn && <ModalInput placeholder="ОГРН" name="ogrn" readOnly={true} />}
          <LandingButton disabled={!agree} type="submit" className={styles.modalButton} variant="fancy" sz="sm">
            Получить доступ
            <LandingChevronRightIcon />
          </LandingButton>

          <div className={styles.modalCheckboxWrap}>
            <ModalCheckboxConnected name="agree" />
            <div className={styles.modalCheckboxText}>
              Нажимая кнопку Вы предоставляете согласие на обработку персональных данных в соответствии с{' '}
              <a href="https://pmtds.ru/legal/pd.pdf" target="_blank" rel="noreferrer">
                Политикой обработки персональных данных
              </a>
            </div>
          </div>
        </form>
      </LandingModal>
    </FormProvider>
  );
};
