import type { Ref } from 'react';
import React from 'react';

import { Select } from '../Select/Select';
import { type AsyncSelectProps } from './AsyncSelectProps';
import { useAsyncSelect } from '@/components/Form/AsyncSelect/useAsyncSelect';
import { Controller, useFormContext } from 'react-hook-form';
import { InputWithErrorTooltip } from '../InputWithErrorTooltip';
import { get } from 'lodash';
import { type HookFormRules } from '@/models/HookFormRules';
import { type EntityWithId } from '@/utils/sortById';

export const AsyncSelect = <TData extends EntityWithId>(props: AsyncSelectProps<TData>, ref: Ref<any>) => {
  const asyncSelect = useAsyncSelect<TData>(props);
  return (
    <div>
      <Select {...asyncSelect} />
    </div>
  );
};

AsyncSelect.displayName = 'AsyncSelect2';

interface AsyncSelectConnectedProps<TData extends EntityWithId>
  extends Omit<AsyncSelectProps<TData>, 'onChange' | 'selected'> {
  rules?: HookFormRules;
  name: string;
  onChange?: (value: number, data: any) => void;
}

export const AsyncSelectConnected = <TData extends EntityWithId>(
  props: AsyncSelectConnectedProps<TData>,
  ref: Ref<any>,
) => {
  const { name, rules, ...rest } = props;
  const {
    formState: { errors },
    // clearErrors,
  } = useFormContext();

  return (
    <div>
      <Controller
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          const errorMessage = (get(errors, name)?.message ?? '') as string;
          return (
            <InputWithErrorTooltip tooltipText={errorMessage} isInvalid={!!errorMessage}>
              <AsyncSelect<TData>
                {...rest}
                onChange={(values: any, data: any) => {
                  onChange(values, data);
                  props.onChange?.(values, data);
                }}
                defaultValue={value}
                isInvalid={!!errorMessage}
              />
            </InputWithErrorTooltip>
          );
        }}
      />
    </div>
  );
};
